<template>
  <div>
    <div v-for="(forma, i) in opcionesFormaEntrega" :key="i">
      <div
        class="repetir_giroInfoFormasEntregaRow"
        @click.stop="forma.habil ? formaEntregaSelected(forma.id) : false"
      >
        <div class="repetir_giroInfoFormasEntregaRowText">
          <div
            class="repetir_giroInfo_item"
            v-if="forma.formaEntrega.length > 0"
          >
            {{ forma.formaEntrega }}
          </div>

          <div
            class="repetir_giroInfo_item"
            v-if="forma.bancoNombre.length > 0"
          >
            {{ forma.bancoNombre }}
          </div>

          <div class="repetir_giroInfo_item" v-if="forma.moneda.length > 0">
            {{ forma.moneda }}
          </div>

          <div
            class="repetir_giroInfo_item"
            v-if="forma.numeroCuentaMostrar.length > 0"
          >
            {{ forma.numeroCuentaMostrar }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    opcionesFormaEntrega: Array,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    // console.log(this.opcionesFormaEntrega);
  },
};
</script>

<style>
.repetir_giroInfoFormasEntregaRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4rem;
  min-height: 2rem;
  border-radius: 5px;
  padding: 0.3rem 0.4rem 0.3rem 0;
  cursor: pointer;
}
.repetir_giroInfoFormasEntregaRowText {
  width: 100%;
  /* padding-left: 0.6rem; */
  color: #4c4c4c;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 1px;
  height: 100%;
}

.repetir_giroInfo_item {
  padding: 0.2rem 0;
}

.repetir_giroInfoFormasEntregaRowCKB {
  width: 0.7rem;
  height: 100%;
}
.repetir_giroSelect {
  width: 1.25rem;
}
</style>