<template>
  <screen_width_cont>
    <div class="myBodyGeneral">
      <div class="centrado" ref="myHeader_repetir_giro">
        <div class="cont_300_basico ancho_acotado">
          <div class="header_top">
            <headerCerrar @cancelar="cerrar" colorIcon="gris" />
          </div>
          <div class="repetir_giro_Title title title_margin_top centrado">
            {{ repetir_giro_titulo }}
          </div>

          <div class="repetir_giro_destinatario_2 repetir_giro_bot_line">
            <div class="repetir_giro_item">
              {{ t("app.repetir_giro.destinatario") }}
            </div>
            <div
              class="repetir_giro_destinatario_item repetir_giro_destinatario_nombre"
            >
              <div>{{ nomre_destinatario }}</div>
              <div class="repetir_giro_destinatario_flag">
                <country-flag class="" :country="bandera"  />
              </div>
            </div>
          </div>
        </div>
      </div>

      <blurComp
        class="blur_sup"
        :style="blur_sup_repetir_giro"
        :scroll_to_="scroll_to_top"
        tipo="sup"
      />

      <div
        class="contenido centrado repetir_giro_contenido"
        ref="repetir_giro_contenido"
        :style="contenido_repetir_giro"
        @scroll="onScroll"
      >
        <div class="cont_300_basico ancho_acotado">
          <div class="repetir_giro_fe">
            <div class="repetir_giro_fe_tit repetir_giro_item">
              {{ t("app.repetir_giro.fe") }}
            </div>

            <div class="repetir_giro_fe_cont repetir_giro_destinatario_item">
              <repetir_giro_forma_entrega
                :opcionesFormaEntrega="opcionesFormaEntrega"
              />
            </div>
          </div>

          <div class="repetir_giro_bot_line"></div>
          <!-- --------------------------------------- -->

          <div class="repetir_giroFormCont">
            <div class="repetir_giroFormOrden">
              <div class="repetir_giroFormDesde">
                <inputSimulador_corto
                  @selectSelected="selectSelectedLocal"
                  :selectSelectedType="selectSelectedType"
                  :label="t('app.cotizador.envias')"
                  :lista="configuracion.data.auxiliares.pais_moneda_destino_id"
                  :cerrarListaPais="cerrarListaPais"
                  id_listaPais="desde"
                  @cerrarOtraListaPais="cerrarOtraListaPaisLocal"
                  :otraListaPaisCerrar="otraListaPaisCerrar"
                  :modoPesoToDestino="modoPesoToDestino"
                  :valor="valorSimuladorOrigen"
                  simbolMoney="CLP"
                  tipo="txtSimuladorOrigen"
                  @calculateAmountL="calculateAmount"
                  :editable="input_editable"
                  @setFocus="cambioValorLocal"
                  @paisSelected="paisSelectedLocal"
                  @changeInputSimuladorMandante="
                    changeInputSimuladorMandanteLocal
                  "
                  :mandante="mandante"
                  :error="errorSimulador"
                  :msgError="textErrorSimulador"
                />
              </div>

              <div class="repetir_giroFormHasta">
                <inputSimulador_corto
                  @selectSelected="selectSelectedLocal"
                  :selectSelectedType="selectSelectedType"
                  :label="t('app.cotizador.dest_recibe')"
                  :lista="configuracion.data.auxiliares.pais_moneda_destino_id"
                  :cerrarListaPais="cerrarListaPais"
                  id_listaPais="hacia"
                  @cerrarOtraListaPais="cerrarOtraListaPaisLocal"
                  :otraListaPaisCerrar="otraListaPaisCerrar"
                  :modoPesoToDestino="modoPesoToDestino"
                  :valor="valorSimuladorDestino"
                  :simbolMoney="objTransaccionLocal.moneda_destino_sigla"
                  tipo="txtSimuladorDestino"
                  @calculateAmountL="calculateAmount"
                  :editable="input_editable"
                  @setFocus="cambioValorLocal"
                  @paisSelected="paisSelectedLocal"
                  @changeInputSimuladorMandante="
                    changeInputSimuladorMandanteLocal
                  "
                  :mandante="mandante"
                  :paisDestinoPreSelected="paisDestinoPreSelected"
                  :error="false"
                  msgError=""
                />
              </div>
            </div>

            <div class="repetir_giroFormDetalle">
              <!-- detalle -->
              <div class="repetir_giroFormDetalleIcon">
                <div class="repetir_giroFormDetalleIconImg">
                  <img :src="iconCotizadorDetalleGrisMenos" alt="" srcset="" />
                </div>
                <div class="repetir_giroFormDetalleIconImg">
                  <img :src="iconCotizadorDetalleCupon" alt="" srcset="" />
                </div>

                <div class="repetir_giroFormDetalleIconImg">
                  <img :src="iconCotizadorIgual" alt="" srcset="" />
                </div>
                <div class="repetir_giroFormDetalleIconImgUltimo">
                  <img :src="iconCotizadorDivide" alt="" srcset="" />
                </div>
              </div>

              <div class="repetir_giroFormDetalle_der">
                <div class="repetir_giroFormDetalle_der_row">
                  <div class="repetir_giroFormDetalleText">
                    <div class="repetir_giroFormDetalleMonto simulador_FormDetalleMonto">
                      <div
                        class="repetir_giroFormDetalleMontoRow repetir_giroFormDetalleText_gris"
                      >
                        {{ format(comision) }} CLP
                      </div>
                    </div>
                    <div class="repetir_giroFormDetalleTextContainer">
                      <div
                        class="repetir_giroFormDetalleTextRow repetir_giroFormDetalleText_gris"
                      >
                        {{ t("app.cotizador.costoEnvio") }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="repetir_giroFormDetalle_der_row">
                  <div class="repetir_giroFormDetalleText">
                    <div class="repetir_giroFormDetalleMonto simulador_FormDetalleMonto">
                      <div
                        class="repetir_giroFormDetalleMontoRow repetir_giroFormDetalleMonto_lila simulador_DetalleText_monto_descuento"
                      >
                        <div v-if="descuentoExiste">
                          {{ format(descuento) }} CLP
                        </div>
                        <div
                          v-else
                          class="repetir_giroFormDetalleMontoRowBtnCuponDesc"
                          @click="activarModalCupon()"
                        >
                          {{ t("app.cotizador.cuponDescuento") }}
                        </div>
                      </div>
                    </div>
                    <div class="repetir_giroFormDetalleTextContainer">
                      <div
                        class="repetir_giroFormDetalleTextRow repetir_giroFormDetalleMonto_lila"
                      >
                        <div
                          v-if="descuentoExiste"
                          class="repetir_giroFormDetalleTextRowDescuento"
                        >
                          {{ t("app.cotizador.descuentoDe") }}&nbsp;
                          <span v-if="objRespCupon.tipo_descuento == '1'"
                            >{{
                              objRespCupon.porcentaje_descuento
                            }}&nbsp;%&nbsp;</span
                          >
                          <span v-if="objRespCupon.tipo_descuento == '2'"
                            >${{
                              format(objRespCupon.valor_descuento)
                            }}&nbsp;</span
                          >
                          {{ t("app.cotizador.enElCostoEnvio") }}
                          <div
                            class="repetir_giroFormDetalleTextRowDescuentoBtn"
                            @click="sinDescuento"
                          >
                            <img :src="iconCerrarAzul" alt="" srcset="" />
                          </div>
                        </div>
                        <!-- <div
                          v-if="descuentoExiste"
                          class="repetir_giroFormDetalleTextRowDescuento"
                        >
                          {{ t("app.cotizador.enElCostoEnvio") }}
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>

                <div class="repetir_giroFormDetalle_der_row">
                  <div class="repetir_giroFormDetalleText">
                    <div class="repetir_giroFormDetalleMonto simulador_FormDetalleMonto">
                      <div
                        class="repetir_giroFormDetalleMontoRow repetir_giroFormDetalleText_gris simulador_DetalleText_monto_descuento"
                      >
                        {{ format(aConvertir) }} CLP
                      </div>
                    </div>
                    <div class="repetir_giroFormDetalleTextContainer">
                      <div
                        class="repetir_giroFormDetalleTextRow repetir_giroFormDetalleText_gris"
                      >
                        {{ t("app.cotizador.aConvertir") }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="repetir_giroFormDetalle_der_row">
                  <div class="repetir_giroFormDetalleText">
                    <div class="repetir_giroFormDetalleMonto simulador_FormDetalleMonto">
                      <div
                        class="repetir_giroFormDetalleMontoRow repetir_giroFormDetalleText_gris simulador_DetalleText_monto_tasa"
                      >
                        {{ tasa_mostrar }}
                      </div>
                    </div>
                    <div class="repetir_giroFormDetalleTextContainer">
                      <div
                        class="repetir_giroFormDetalleTextRow repetir_giroFormDetalleText_gris"
                      >
                        {{ t("app.cotizador.tasaGarantizada") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- --------------------------------------- -->
        </div>
      </div>

      <blurComp
        class="blur_inf"
        :style="blur_inf_repetir_giro"
        :scroll_to_="scroll_to_bottom"
        tipo="inf"
      />

      <div class="footer_estrecho_centrado" ref="footer_repetir_giro">
        <div class="footer_estrecho">
          <div class="repetir_giro_Btn_container">
            <div class="repetir_giro_Btn">
              <btnAction
                :textBtn="t('app.comunes.btns.continuar')"
                @action="simulator"
                colorBack="azul"
                colorText="blco"
                :estadoBtn="estadoBtn"
                :msg_completar="msg_completar"
              />
            </div>

            <div class="repetir_giro_Etapas">
              <pasosGiro paso="1" totales="6" />
            </div>
          </div>
          <div class="footer_estrecho">
            <div class="footer_env_seguro">
              <enviosSeguros @callEnvioSeguroModal_="callEnvioSeguroModal" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>


<script>
import { modalController, alertController } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import headerCerrar from "../components/generales/headerCerrar.vue";
import cuponDescuento from "@/views/modales/cuponDescuento.vue";
import inputSimulador_corto from "@/components/simulador/inputSimulador_corto.vue";
import btnAction from "../components/generales/btnAction.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import enviosSeguros from "../components/generales/envios_seguros.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";
import blurComp from "../components/generales/blur.vue";
import {
  blur_sup_scroll,
  blur_inf_scroll,
  contenido_style,
  blur_sup_style,
  blur_inf_style,
} from "../assets/funciones/blur";
import { mapActions, mapMutations, mapState } from "vuex";
import repetir_giro_forma_entrega from "../components/simulador/repetir_giro_forma_entrega.vue";
import CountryFlag from "vue-country-flag-next";
import envioSeguroHome from "./modales/enviosSegurosHome.vue";

export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  name: "repetir_giro",
  components: {
    headerCerrar,
    inputSimulador_corto,
    btnAction,
    pasosGiro,
    enviosSeguros,
    screen_width_cont,
    blurComp,
    repetir_giro_forma_entrega,
    CountryFlag,
  },
  data() {
    return {
      valorSimuladorOrigen: 500000,
      valorSimuladorDestino: "0",
      tipo_cambioModal: 0,
      errorSimulador: true,
      textErrorSimulador: "",
      comision: 0,
      descuento: 0,

      descuentoExiste: false,
      objRespCupon: null,
      modoPesoToDestino: true,
      enVista: true,
      primeraCarga: true,
      aConvertir: 0,
      selectSelectedType: "",
      iconCotizadorDetalleGrisMenos: require("../assets/imgs/cotizador/iconCotizadorDetalleGrisMenos.svg"),
      iconCotizadorDetalleCupon: require("../assets/imgs/cotizador/iconCotizadorDetalleAzul.svg"),
      iconCotizadorIgual: require("../assets/imgs/cotizador/iconCotizadorDetalleIgual.svg"),
      iconCotizadorDivide: require("../assets/imgs/cotizador/iconCotizadorDetalleDivide.svg"),
      iconCotizadorSendBlue: require("../assets/imgs/iconCotizadorSendBlue.svg"),
      iconCerrarAzul: require("../assets/imgs/iconCerrarAzul.svg"),
      mandante: "desde",
      btnActive: true,
      header: 0,
      footer: 0,
      scroll_to_bottom: true,
      scroll_to_top: true,
      contenido_repetir_giro: {},
      blur_sup_repetir_giro: {},
      blur_inf_repetir_giro: {},
      input_editable: false,
      msg_completar: this.t("app.repetir_giro.seleccione_fe"),
    };
  },
  computed: {
    ...mapState([
      "transaccion",
      "costos_pais",
      "remitente",
      "session_confirm",
      "configuracion",
      "resumen",
      "cardBodyPaises",
      "paises",
      "desde",
      "procesoGeneral",
      "nav_stack",
      "widthScreen",
      "monto_inicio",
      "pais_destino_inicial",
      "heigthScreen",
      "respSelectDestinatario",
      "menuSpDesde",
      "plataforma",
      "i18n_locales",
    ]),

    objTransaccionLocal() {
      let aux = {
        giro_gastos_envio: 0,
        valor_cambio: 0,
        monto_origen: 0,
        tasa_invertida: "",
        monto_destino: 0,
        moneda_origen_sigla: "",
        moneda_destino_sigla: "",
        valor_minimo_origen: 0,
        valor_maximo_origen: 0,
        monto_pagar: 0,
        tasaSimbolA: "",
        tasaSimbolB: "",
        tasa: 0,
        moneda_destino_id: "",
      };

      if (this.transaccion.giro_gastos_envio)
        aux.giro_gastos_envio = this.transaccion.giro_gastos_envio;
      if (this.transaccion.valor_cambio)
        aux.valor_cambio = this.transaccion.valor_cambio;
      if (this.transaccion.monto_origen)
        aux.monto_origen = this.transaccion.monto_origen;
      if (this.transaccion.tasa_invertida)
        aux.tasa_invertida = this.transaccion.tasa_invertida;
      if (this.transaccion.monto_destino)
        aux.monto_destino = this.transaccion.monto_destino;
      if (this.transaccion.moneda_origen_sigla)
        aux.moneda_origen_sigla = this.transaccion.moneda_origen_sigla;
      if (this.transaccion.moneda_destino_sigla)
        aux.moneda_destino_sigla = this.transaccion.moneda_destino_sigla;
      if (this.transaccion.valor_minimo_origen)
        aux.valor_minimo_origen = this.transaccion.valor_minimo_origen;
      if (this.transaccion.valor_maximo_origen)
        aux.valor_maximo_origen = this.transaccion.valor_maximo_origen;
      if (this.transaccion.monto_pagar)
        aux.monto_pagar = this.transaccion.monto_pagar;
      if (this.transaccion.moneda_destino_id) {
        aux.moneda_destino_id = this.transaccion.moneda_destino_id;
      }

      if (this.transaccion.tasa_invertida == "1") {
        aux.tasaSimbolA = this.transaccion.moneda_origen_sigla;
        aux.tasaSimbolB = this.transaccion.moneda_destino_sigla;
        aux.tasa = parseFloat(1 / this.transaccion.valor_cambio)
          .toFixed(2)
          .replace(".", ",");
      } else {
        aux.tasaSimbolA = this.transaccion.moneda_destino_sigla;
        aux.tasaSimbolB = this.transaccion.moneda_origen_sigla;

        aux.tasa = this.transaccion.valor_cambio;
      }
      // console.log(aux.tasa);
      return aux;
    },
    tiempoEntregaNumber() {
      let aux = "1";

      return aux;
    },
    estadoBtn() {
      let aux = "inactive";
      if (!this.errorSimulador && this.transaccion.detalle_entrega_id) {
        aux = "active";
      }

      return aux;
    },
    tasa_mostrar() {
      let aux = "";
      if (this.objTransaccionLocal.tasa && this.transaccion.moneda_destino_id) {
        if (this.objTransaccionLocal.tasa < 1) {
          let decimales_index = this.configuracion.monedas.findIndex(
            (moneda) => {
              return moneda.id == this.transaccion.moneda_destino_id;
            }
          );
          let decimales = 0;
          if (decimales_index > -1) {
            decimales =
              this.configuracion.monedas[decimales_index].cantidad_decimales;
          }
          let tasa_calc = 1 / this.objTransaccionLocal.tasa;
          // let formato = new RegExp(
          //   "^-?\\d+(?:.\\d{0," + (decimales || -1) + "})?"
          // );
          tasa_calc = parseFloat(tasa_calc.toFixed(decimales));
          tasa_calc = tasa_calc.toLocaleString(
            `${this.locale}-${this.locale.toUpperCase()}`
          );
          aux = `1 ${this.objTransaccionLocal.moneda_destino_sigla} = ${tasa_calc} ${this.objTransaccionLocal.moneda_origen_sigla}`;
        } else {
          let decimales_index = this.configuracion.monedas.findIndex(
            (moneda) => {
              return moneda.id == this.transaccion.moneda_destino_id;
            }
          );
          let decimales = 0;
          if (decimales_index > -1) {
            decimales =
              this.configuracion.monedas[decimales_index].cantidad_decimales;
          }
          // let tasa_calc = this.objTransaccionLocal.tasa;
          // let formato = new RegExp(
          //   "^-?\\d+(?:.\\d{0," + (decimales || -1) + "})?"
          // );
          let tasa_calc = parseFloat(
            this.objTransaccionLocal.tasa.toFixed(decimales)
          );
          tasa_calc = tasa_calc.toLocaleString(
            `${this.locale}-${this.locale.toUpperCase()}`
          );
          aux = `1 ${this.objTransaccionLocal.moneda_origen_sigla} = ${tasa_calc} ${this.objTransaccionLocal.moneda_destino_sigla}`;
        }
      }

      return aux;
    },
    nomre_destinatario() {
      let aux = "";
      if (
        this.enVista &&
        this.transaccion &&
        this.transaccion.id_persona &&
        this.remitente &&
        this.remitente.destinatarios
      ) {
        aux = `${
          this.remitente.destinatarios[this.transaccion.id_persona].nombre1
        } ${
          this.remitente.destinatarios[this.transaccion.id_persona].apellido1
        } ${
          this.remitente.destinatarios[this.transaccion.id_persona].apellido2
        }`;
      }
      return aux;
    },
    pais_destinatario() {
      let aux = "";
      if (
        this.transaccion &&
        this.transaccion.pais_destino &&
        this.configuracion
      ) {
        aux = this.configuracion.pais[this.transaccion.pais_destino].nombre;
      }
      return aux;
    },
    opcionesFormaEntrega() {
      let aux = [];
      if (
        this.remitente.destinatarios[this.transaccion.id_persona] &&
        this.remitente.destinatarios[this.transaccion.id_persona]
          .detalle_entrega &&
        this.remitente.destinatarios[this.transaccion.id_persona]
          .detalle_entrega[this.transaccion.detalle_entrega_id]
      ) {
        let aux2 = Object.entries(
          this.remitente.destinatarios[this.transaccion.id_persona]
            .detalle_entrega
        );
        aux2.forEach((element) => {
          if (
            element[0] != "0" &&
            element[0] == this.transaccion.detalle_entrega_id
          ) {
            aux.push(element[1]);
          }
        });

        aux.forEach((formaEntrega) => {
          if (formaEntrega.id_banco_destino != "0") {
            if (formaEntrega.forma_entrega_id == "1") {
              formaEntrega.bancoNombre = formaEntrega.bancos_nombre;
              formaEntrega.numeroCuentaMostrar = `Cta. N° ${formaEntrega.numero_cuenta}`;
            } else if (formaEntrega.forma_entrega_id == "6") {
              formaEntrega.bancoNombre = formaEntrega.bancos_nombre;
              formaEntrega.numeroCuentaMostrar = `Cta. N° ${formaEntrega.numero_cuenta_wallet}`;
            } else {
              formaEntrega.bancoNombre = "";
              formaEntrega.numero_cuenta = "";
              formaEntrega.bancoNombre = `${formaEntrega.bancos_nombre}`;
              formaEntrega.numeroCuentaMostrar = "";
            }
          } else {
            formaEntrega.bancoNombre = "aa";
            formaEntrega.numero_cuenta = "";
            let banco_caja_index = this.configuracion.pais[
              this.remitente.destinatarios[this.transaccion.id_persona]
                .pais_moneda_destino_id
            ].banco_caja.findIndex((bco_caja) => {
              bco_caja[0] == formaEntrega.banco_caja;
            });
            if (banco_caja_index > -1) {
              formaEntrega.bancoNombre =
                this.configuracion.pais[
                  this.remitente.destinatarios[
                    this.transaccion.id_persona
                  ].pais_moneda_destino_id
                ].banco_caja[banco_caja_index][0];
            }
          }

          if (formaEntrega.forma_entrega_id == "2") {
            formaEntrega.formaEntrega = `${this.t(
              "app.cotizadorDestinatario.entregaCaja"
            )}`;
            formaEntrega.moneda = `${this.t(
              `variables.monedas.${formaEntrega.moneda_caja}.plural`
            )}`;
            formaEntrega.habil = true;
          } else if (formaEntrega.forma_entrega_id == "1") {
            formaEntrega.formaEntrega = `${this.t(
              "app.cotizadorDestinatario.deposito"
            )}`;
            formaEntrega.moneda = `${this.t(
              `variables.monedas.${formaEntrega.moneda_destino_id}.plural`
            )}`;
            formaEntrega.habil = true;
          } else if (formaEntrega.forma_entrega_id == "6") {
            formaEntrega.formaEntrega = `${this.t(
              "app.cotizadorDestinatario.ewallet"
            )}`;
            formaEntrega.moneda = `${this.t(
              `variables.monedas.${formaEntrega.moneda_destino_id}.plural`
            )}`;
            formaEntrega.habil = true;
          }
        });
      }

      if (aux.length > 0) {
        aux.sort((a, b) => {
          if (a.forma_entrega_id === b.forma_entrega_id) {
            return a.bancoNombre < b.bancoNombre ? -1 : 1;
          } else {
            return a.forma_entrega_id < b.forma_entrega_id ? 1 : -1;
          }
        });
      }

      return aux;
    },

    repetir_giro_titulo() {
      let aux = "*";
      // if (this.desde == "destinatarios") {
      //   aux = this.t("app.repetir_giro.tit_enviar");
      // } else if (this.desde == "home") {
      aux = this.t("app.repetir_giro.tit_repetir");
      // }
      return aux;
    },
    bandera() {
      let aux = "";
      if (this.enVista) {
        let aux2 = this.transaccion.pais_destino.split("_")[0];
        if (aux2 == "68") {
          aux = "bo";
        } else if (aux2 == "99") {
          aux = "do";
        } else if (aux2 == "170") {
          aux = "co";
        } else if (aux2 == "218") {
          aux = "ec";
        } else if (aux2 == "332") {
          aux = "ht";
        } else if (aux2 == "400") {
          aux = "cn";
        } else if (aux2 == "450") {
          aux = "es";
        } else if (aux2 == "604") {
          aux = "pe";
        } else if (aux2 == "800") {
          aux = "br";
        } else if (aux2 == "900") {
          aux = "ar";
        }
      }

      return aux;
    },
  },
  methods: {
    ...mapActions([
      "validacionesAux",
      "limpiarTransaccion",
      "validarDestinoSelected",
      "calcular_monto_origen",
      "getShippingCost",
      "getShippingCost_factor",
      "check_getShippingCost",
      "calcular_monto_origen",
      "validarTarjetaBeneficiario",
      "valida_monthly_amount",
    ]),
    ...mapMutations([
      "updateTransaccion",
      "eliminarTransaccionPaisDestino",
      "updateDesde",
      "updateBloquearNavegacion",
      "updateProcesoGeneral",
      "updateMenuSpDesde",
      "set_nav_stack",
      "reset_transaccion",
      "setshowSpinner",
      "updateDataDestinSelected",
      "updateCostoPais",
      "set_pre_values_cotizador",
    ]),

    cerrar() {
      this.enVista = false;
      // this.updateTransaccion({
      //   moneda_destino_sigla: "",
      //   banco: "",
      //   id_banco_destino: "",
      //   tipo_cuenta: "",
      //   cuenta_corriente: "",
      //   motivo_transaccion: "",
      //   numero_cuenta: "",
      //   id_persona: "",
      //   forma_entrega_id: "",
      //   tasa_mostrar: "",
      //   detalle_entrega_id: "",
      //   monto_origen:0,
      //   monto_pagar:0,
      //   monto_destino:0,
      //   pais_destino:null
      // });
      this.reset_transaccion();
      this.set_pre_values_cotizador({
        monto_inicio: this.monto_inicio,
        pais_destino_inicial: this.pais_destino_inicial,
      });
      this.$router.push({ name: this.desde, replace: true });
    },

    nueva_forma_entrega_local() {
      this.updateProcesoGeneral("repetir_giro");
      this.updateDataDestinSelected({
        tipo: "nueva_forma_entrega",
        data: this.remitente.destinatarios[this.transaccion.id_persona],
        indexFE: 0,
      });
      this.$router.push({ name: "destinMyDataForm", replace: true });
    },

    //---------------------------------------
    format_destin_dolar(destino) {
      
      let decimales_index = this.configuracion.monedas.findIndex((moneda) => {
        return moneda.id == this.transaccion.moneda_destino_id;
      });
      let decimales = 0;
      if (decimales_index > -1) {
        decimales =
          this.configuracion.monedas[decimales_index].cantidad_decimales;
      }

      let aux = Math.pow(10, decimales);

      if (typeof destino == "string") {
        let aux3 = destino.split(".");
        let aux2 = ``;
        if (decimales == 1) {
          if (!aux3[1] || aux3[1].length == 0) {
            aux2 = `${aux3[0]}0.0`;
          } else if (aux3[1] && aux3[1].length == 1) {
            aux2 = `${aux3[0]}.${aux3[1]}`;
          } else if (aux3[1] && aux3[1].length == 2) {
            aux2 = `${aux3[0]}${aux3[1].substring(0, 1)}.${aux3[1].substring(
              1,
              1
            )}`;
          }
        } else if (decimales == 2) {
          if (!aux3[1] || aux3[1].length == 0) {
            aux2 = `${aux3[0]}00.0`;
          } else if (aux3[1] && aux3[1].length == 1) {
            aux2 = `${aux3[0]}${aux3[1]}0.0`;
          } else if (aux3[1] && aux3[1].length == 2) {
            aux2 = `${aux3[0]}${aux3[1]}.0`;
          }
        }

        destino = Math.floor(aux2) / aux;
      } else {
        let aux2 = destino;
        for (let index = 0; index < decimales; index++) {
          aux2 = aux2 * 10;
        }
        destino = Math.floor(aux2) / aux;
      }
      
      destino = parseFloat(destino);

      destino = destino.toLocaleString(
        `${this.locale}${this.i18n_locales[this.locale].locales}`
      );

      return destino;
    },
    unformat(input) {
      if (typeof input == "object") {
        //
      } else {
        if (typeof input != "string") return;
        let str = input.split(".").join("");
        str = parseInt(str);
        return str;
      }
    },
    format(input) {
      if (typeof input == "object") {
        //
      } else {
        let str = input.toString();
        str = str
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{3})$/, "$1.$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        return str;
      }
    },
    async paisSelectedLocal(pais) {
      if (!this.primeraCarga) {
        this.descuentoExiste = false;
        this.objRespCupon = null;
      }

      let tasa_index = -1;

      tasa_index = this.configuracion.tasasCambio.findIndex((tasa) => {
        return tasa.id_pais_destino == pais;
      });

      if (tasa_index > -1) {
        await this.updateTransaccion({
          valor_cambio: parseFloat(
            this.configuracion.tasasCambio[tasa_index].valor_cambio
          ),
          tasa_transaccion_id:
            this.configuracion.tasasCambio[tasa_index].tasa_transaccion_id,

          moneda_destino_id: "",
        });
      }

      let valor_min_max_index = this.configuracion.costosEnvio.findIndex(
        (costo_srv) => {
          return costo_srv.pais_destino == pais.split("_")[0];
        }
      );

      if (valor_min_max_index > -1) {
        await this.updateTransaccion({
          valor_minimo_origen:
            this.configuracion.costosEnvio[valor_min_max_index]
              .valor_minimo_origen,
          valor_maximo_origen:
            this.configuracion.costosEnvio[valor_min_max_index]
              .valor_maximo_origen,
        });
      }

      await this.validarDestinoSelected({
        pais_val: pais,
      });

      if (this.modoPesoToDestino) {
        await this.calculateAmount({
          tipo: "txtSimuladorOrigen",
        });
        this.validarErroresMonto();
      } else {
        await this.calculateAmount({
          tipo: "txtSimuladorDestino",
          valor: this.transaccion.monto_destino,
        });
        this.validarErroresMonto();
      }

      // console.log(this.transaccion);
    },

    validarErroresMonto() {
      this.textErrorSimulador = ``;
      this.errorSimulador = false;
      let simulador_origen;

      simulador_origen = this.valorSimuladorOrigen;
      if (typeof this.valorSimuladorOrigen == "string") {
        simulador_origen = this.unformat(this.valorSimuladorOrigen);
      }

      if (isNaN(simulador_origen)) {
        this.errorSimulador = true;
        this.textErrorSimulador = `cantidad a enviar?`;

        return false;
      }

      if (simulador_origen < this.objTransaccionLocal.valor_minimo_origen) {
        this.errorSimulador = true;
        this.textErrorSimulador = `Depósito minimo de ${this.format(
          parseInt(this.objTransaccionLocal.valor_minimo_origen)
        )}`;

        return false;
      }

      if (simulador_origen > this.objTransaccionLocal.valor_maximo_origen) {
        this.errorSimulador = true;
        this.textErrorSimulador = `Depósito maximo de ${this.format(
          parseInt(this.objTransaccionLocal.valor_maximo_origen)
        )}`;

        return false;
      }

      return true;
    },

    async calculateAmount(data) {
      let costo_servicio_encontrado = false;
      let datos_calc_orig = {};

      let id = data.tipo;
      if (typeof data.valor != "undefined") {
        if (data.tipo == "txtSimuladorOrigen") {
          this.valorSimuladorOrigen = data.valor;
        } else {
          this.valorSimuladorDestino = data.valor;
        }
      }

      let destino = 0;
      let origen_temp = 0;

      let valor;
      let objetoTransaccionTemp = {};
      switch (id) {
        case "txtSimuladorOrigen":
          if (typeof this.valorSimuladorOrigen == "string") {
            valor = this.valorSimuladorOrigen.replaceAll(this.i18n_locales[this.locale].miles_str, "");
            valor = parseFloat(valor);
          } else {
            valor = this.valorSimuladorOrigen;
          }
          origen_temp = valor;
          costo_servicio_encontrado = await this.check_getShippingCost(valor);

          if (!costo_servicio_encontrado) {
            this.comision = 0;
            this.aConvertir = 0;
            this.valorSimuladorDestino = 0;
            objetoTransaccionTemp.monto_destino = "0";
            objetoTransaccionTemp.monto_origen = "0";
            this.valorSimuladorOrigen = this.format(valor);
            break;
          }
          await this.getShippingCost(valor);

          this.comision = parseFloat(
            this.objTransaccionLocal.giro_gastos_envio
          );

          if (this.objRespCupon) {
            if (this.objRespCupon.tipo_descuento == "1") {
              this.descuento = parseInt(
                this.comision * (this.objRespCupon.porcentaje_descuento / 100)
              );
            } else if (this.objRespCupon.tipo_descuento == "2") {
              if (this.comision > this.objRespCupon.valor_descuento) {
                this.descuento = this.objRespCupon.valor_descuento;
              } else {
                this.descuento = this.comision;
              }
            }
          } else {
            this.descuento = 0;
          }

          valor =
            valor -
            (this.objTransaccionLocal.giro_gastos_envio - this.descuento);
          this.aConvertir = valor;

          if (this.objTransaccionLocal.moneda_destino_id == "2") {
            objetoTransaccionTemp.monto_destino = (Math.floor((valor * this.objTransaccionLocal.valor_cambio)*100))/100;
            valor = this.format_destin_dolar(
              valor * this.objTransaccionLocal.valor_cambio
            );
          } else {
            objetoTransaccionTemp.monto_destino = (valor * this.objTransaccionLocal.valor_cambio);
            valor = this.format(
              Math.floor(
                parseInt(valor * this.objTransaccionLocal.valor_cambio)
              )
            );
          }

          this.valorSimuladorDestino = valor;
          this.valorSimuladorOrigen = this.format(origen_temp);
          
          objetoTransaccionTemp.monto_origen = this.valorSimuladorOrigen;

          break;

        case "txtSimuladorDestino":
          if (typeof this.valorSimuladorDestino == "string" && !this.primeraCarga) {
            destino = this.valorSimuladorDestino;
            // console.log("1->", destino, this.primeraCarga);
            let aux = destino.split(this.i18n_locales[this.locale].deci_str);
            if (aux[1]) {
              let aux2 = 1;
              if (aux[1].length > 1) aux2 = 2;
              destino = `${aux[0]}${
                this.i18n_locales[this.locale].deci_str
              }${aux[1].substring(0, aux2)}`;
            }
            // console.log("2->", destino);
            destino = destino.replaceAll(
              this.i18n_locales[this.locale].miles_str,
              ""
            );
            destino = destino.replaceAll(
              this.i18n_locales[this.locale].deci_str,
              "."
            );
          } else {
            destino = this.valorSimuladorDestino;
          }
          // console.log("3->", destino);
          datos_calc_orig = await this.calcular_monto_origen({
            destino: destino,
            objRespCupon: this.objRespCupon,
            valor_cambio: this.objTransaccionLocal.valor_cambio,
          });
          this.descuento = this.format(datos_calc_orig.descuento);
          this.aConvertir = datos_calc_orig.aConvertir;
          origen_temp = datos_calc_orig.origen_temp;
          this.comision = datos_calc_orig.comision;
          this.valorSimuladorOrigen = this.format(origen_temp);
          objetoTransaccionTemp.monto_destino = destino;
          objetoTransaccionTemp.monto_origen = this.valorSimuladorOrigen;
          if (this.objTransaccionLocal.moneda_destino_id == "2") {
            this.valorSimuladorDestino = this.format_destin_dolar(destino);
          } else {
            this.valorSimuladorDestino = this.format(destino);
          }
          this.primeraCarga = false;
          break;
      }

      this.updateTransaccion(objetoTransaccionTemp);
    },

    async activarModalCupon() {
      this.objRespCupon = null;
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: cuponDescuento,
      });

      modal.onDidDismiss().then((codigo) => {
        this.updateBloquearNavegacion(false);

        if (codigo.data.codigo) {
          this.descuentoExiste = true;
          this.objRespCupon = codigo.data.codigo;
        } else {
          this.descuentoExiste = false;
          this.objRespCupon = null;
        }
        this.calculateAmount({
          tipo: this.modoPesoToDestino
            ? "txtSimuladorOrigen"
            : "txtSimuladorDestino",
        });
      });

      return modal.present();
    },
    sinDescuento() {
      this.descuentoExiste = false;
      this.objRespCupon = null;

      this.calculateAmount({
        tipo: this.modoPesoToDestino
          ? "txtSimuladorOrigen"
          : "txtSimuladorDestino",
      });
      this.updateTransaccion({
        codigo_id: "",
        cupon: "",
      });
    },

    cambioValorLocal(valor) {
      if (!this.primeraCarga) {
        if (valor == "desde") {
          this.modoPesoToDestino = true;
          this.calculateAmount({ tipo: "txtSimuladorOrigen" });
        } else {
          this.modoPesoToDestino = false;
          this.calculateAmount({ tipo: "txtSimuladorDestino" });
        }
      }
    },

    async simulator() {
      this.setshowSpinner(false);
      this.textErrorSimulador = ``;
      let simulador_origen = this.unformat(this.valorSimuladorOrigen);
      let simulador_destino = this.unformat(this.valorSimuladorDestino);

      if (!isNaN(simulador_origen) && !isNaN(simulador_destino)) {
        if (simulador_origen >= this.objTransaccionLocal.valor_minimo_origen) {
          if (
            simulador_origen <= this.objTransaccionLocal.valor_maximo_origen
          ) {
            this.errorSimulador = false;
            this.updateTransaccion({ monto_pagar: simulador_origen });

            if (typeof this.remitente.id == "undefined") {
              return false;
            }

            if (!(await this.validMonthlyAmount(simulador_origen))) {
              return;
            }

            if (this.objRespCupon == null) {
              this.objRespCupon = {
                porcentaje_descuento: 0,
                id: null,
                codigo_descuento: null,
                veces_usado: null,
                descuento: 0,
                tasa_mostrar: this.tasa_mostrar,
              };
            } else {
              this.objRespCupon.descuento = this.descuento;
              this.objRespCupon.tasa_mostrar = this.tasa_mostrar;
            }
            this.objRespCupon.conservar_destinatario = true;
            await this.validacionesAux(this.objRespCupon);
          } else {
            this.errorSimulador = true;
            this.textErrorSimulador = `Depósito maximo de ${this.format(
              parseInt(this.objTransaccionLocal.valor_maximo_origen)
            )}`;
            return false;
          }
        } else {
          this.errorSimulador = true;
          this.textErrorSimulador = `Depósito minimo de ${this.format(
            parseInt(this.objTransaccionLocal.valor_minimo_origen)
          )}`;
          return false;
        }
      } else {
        return false;
      }

      if (this.session_confirm > 0) {
        // console.log(
        //   this.remitente.destinatarios[this.transaccion.id_persona]
        //     .detalle_entrega[this.transaccion.detalle_entrega_id]
        //     .forma_entrega_id,
        //   this.remitente.destinatarios[this.transaccion.id_persona]
        //     .detalle_entrega[this.transaccion.detalle_entrega_id]
        // );
        let fe_id =
          this.remitente.destinatarios[this.transaccion.id_persona]
            .detalle_entrega[this.transaccion.detalle_entrega_id]
            .forma_entrega_id;
        let banco_id = "";
        let cta_nro = "";
        if (fe_id == "1") {
          banco_id =
            this.remitente.destinatarios[this.transaccion.id_persona]
              .detalle_entrega[this.transaccion.detalle_entrega_id]
              .id_banco_destino;
          cta_nro =
            this.remitente.destinatarios[this.transaccion.id_persona]
              .detalle_entrega[this.transaccion.detalle_entrega_id]
              .numero_cuenta;
        } else if (fe_id == "6") {
          banco_id =
            this.remitente.destinatarios[this.transaccion.id_persona]
              .detalle_entrega[this.transaccion.detalle_entrega_id].bco_wallet;
          cta_nro =
            this.remitente.destinatarios[this.transaccion.id_persona]
              .detalle_entrega[this.transaccion.detalle_entrega_id]
              .numero_cuenta_wallet;
        }

        let destinatario = {
          accion: "seleccionar_transaccion",
          id_persona: this.transaccion.id_persona,
          forma_entrega_id:
            this.remitente.destinatarios[this.transaccion.id_persona]
              .detalle_entrega[this.transaccion.detalle_entrega_id]
              .forma_entrega_id,
          tipo_cuenta:
            this.remitente.destinatarios[this.transaccion.id_persona]
              .detalle_entrega[this.transaccion.detalle_entrega_id].tipo_cuenta,
          id_banco_destino: banco_id,
          cuenta_corriente: cta_nro,
          nro_cuenta: cta_nro,
          motivo_transaccion: "",
          detalle_entrega_id: this.transaccion.detalle_entrega_id,
        };

        this.validarTarjetaBeneficiario(destinatario);
      }
    },

    async validMonthlyAmount(simulador_origen) {
      // console.log(this.objTransaccionLocal, this.transaccion);
      let resp_valida_monto = await this.valida_monthly_amount(
        simulador_origen
      );

      if (resp_valida_monto.resp) {
        return true;
      } else {
        let mensaje = "";
        if (resp_valida_monto.cod == "31") {
          if (
            resp_valida_monto.max >=
            this.objTransaccionLocal.valor_minimo_origen
          ) {
            mensaje = `${this.t(
              "app.cotizador.monto_max_mensual_disp"
            )} $${this.format(resp_valida_monto.max)}`;
          } else {
            mensaje = `${this.t("app.cotizador.monto_max_mensual_alcanzado")}`;
          }
        }
        let alert = await alertController.create({
          header: this.t("app.logIn.error"),
          subHeader: mensaje,
          buttons: ["Ok"],
          backdropDismiss: false,
        });
        await alert.present();

        return false;
      }
    },

    changeInputSimuladorMandanteLocal(manda) {
      this.mandante = manda;
    },

    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scroll_to_top = blur_sup_scroll(scrollTop);
      this.scroll_to_bottom = blur_inf_scroll(
        scrollTop,
        clientHeight,
        scrollHeight
      );
    },
    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: envioSeguroHome,
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },
  },
  watch: {
    valorSimuladorOrigen() {
      if (!this.primeraCarga) {
        this.validarErroresMonto();
      }
    },
    heigthScreen() {
      this.contenido_repetir_giro = contenido_style(
        this.heigthScreen,
        this.header,
        this.footer,
        this.plataforma == "web" ? 63 : 0
      );
    },
    respSelectDestinatario() {
      if (this.respSelectDestinatario.status && this.enVista) {
        if (this.respSelectDestinatario.resp) {
          this.$router.push({ name: "cotizadorMotivoRemesa", replace: true });
        }
        this.setshowSpinner(false);
      }
    },
  },
  async ionViewWillEnter() {
    this.descuentoExiste = false;
    this.updateMenuSpDesde({ desde: "repetir_giro", menuSp: false });
    this.enVista = true;
    this.updateProcesoGeneral("repetir_giro");

    if (this.transaccion.monto_origen) {
      // console.log("this.transaccion.monto_origen", this.transaccion.monto_origen, this.transaccion.monto_destino, this.modoPesoToDestino);
      this.valorSimuladorOrigen = this.transaccion.monto_origen;

      if (
        this.transaccion.descuento > 0 &&
        this.transaccion.tipo_descuento != ""
      ) {
        this.descuentoExiste = true;
        this.objRespCupon = {
          tipo_descuento: this.transaccion.tipo_descuento,
          porcentaje_descuento: this.transaccion.porcentaje_descuento,
          valor_descuento: this.transaccion.valor_descuento,
          id: null,
          cupon: this.transaccion.cupon,
        };

        this.descuento = this.transaccion.descuento;
        // console.log("ok", this.objRespCupon);
      }
    } else {
      this.valorSimuladorOrigen = this.monto_inicio;
    }

    if (this.transaccion.pais_destino) {
      this.paisDestinoPreSelected = this.transaccion.pais_destino;
    } else {
      this.paisDestinoPreSelected = this.pais_destino_inicial;
    }
  },
  ionViewDidEnter() {
    // console.log(this.transaccion);
    if (this.transaccion.id_persona && this.transaccion.detalle_entrega_id) {
      this.modoPesoToDestino = false;
      this.paisSelectedLocal(this.transaccion.pais_destino);
      this.input_editable = true;
    } else {
      this.valorSimuladorOrigen = 500000;
      this.valorSimuladorDestino = "0";
      this.tipo_cambioModal = 0;
      this.errorSimulador = true;
      this.textErrorSimulador = "";
      this.comision = 0;
      this.descuento = 0;

      this.descuentoExiste = false;
      this.objRespCupon = null;
      this.modoPesoToDestino = true;
      this.aConvertir = 0;
      this.selectSelectedType = "";
      this.mandante = "desde";
      this.input_editable = false;
      this.msg_completar = this.t("app.repetir_giro.seleccione_fe");
    }

    // this.primeraCarga = false;

    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_repetir_giro"].offsetHeight;
        this.footer = this.$refs["footer_repetir_giro"].offsetHeight;
        this.cont_visible = true;
        this.contenido_repetir_giro = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
        this.blur_sup_repetir_giro = blur_sup_style(this.header);
        this.blur_inf_repetir_giro = blur_inf_style(this.footer);
      }, 100);

      setTimeout(() => {
        if (
          this.$refs["repetir_giro_contenido"].scrollHeight >
          this.$refs["repetir_giro_contenido"].clientHeight
        ) {
          this.scroll_to_bottom = false;
        }
      }, 100);
    });
  },
  ionViewWillLeave() {
    this.enVista = false;
  },
};
</script>

<style>
.repetir_giro_Title {
  color: #801b1c;
}

.repetir_giro_pais {
  padding-top: 0.2rem;
  padding-bottom: 0.5rem;
}

.repetir_giro_pais_t1 {
  font-size: 0.7rem;
  color: #787878;
  font-weight: 600;
}

.repetir_giro_pais_t2 {
  font-size: 1rem;
  color: #614ad3;
}

.repetir_giro_destinatario {
  padding-top: 1rem;
  font-size: 1.2rem;
  color: #614ad3;
  font-weight: 600;
}
.repetir_giro_destinatario_2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.repetir_giro_destinatario_item {
  padding-left: 0.6rem;
}
.repetir_giro_destinatario_nombre {
  color: #614ad3;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.repetir_giro_contenido {
  padding-bottom: 2rem;
}
.repetir_giro_item {
  font-size: 0.8rem;
  color: #787878;
  font-weight: 600;
}
.repetir_giro_fe_tit {
  /* padding-bottom: 0.3rem; */
}

/* --------------------------------- */
.repetir_giroFormCont {
  margin-top: 2rem;
}

.repetir_giroFormDetalle {
  margin-top: 1.125rem;
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  position: relative;
}

.repetir_giroFormDetalleIcon {
  width: 1.125rem;
  height: fit-content;
}

.repetir_giroFormDetalleIconImg {
  width: 1.5rem;
  position: relative;
  height: 2.5rem;
  border-left: 2px solid #787878;
}

.repetir_giroFormDetalleIconImgUltimo {
  width: 1.38rem;
  position: relative;
  height: 2.5rem;
}

.repetir_giroFormDetalleIcon img {
  position: absolute;
  width: 100%;
  top: 0;
  left: -0.75rem;
}

.repetir_giroFormDetalleIconImgUltimo img {
  position: absolute;
  width: 100%;
  top: 0;
  left: -0.68rem;
}

.repetir_giroFormDetalle_der {
  display: flex;
  flex-direction: column;
  position: relative;

  width: 100%;
}

.repetir_giroFormDetalle_der_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  height: 2.5rem;
  padding-top: 0.2rem;
}

.repetir_giroFormDetalleMonto {
  position: relative;
  font-family: "Roboto";
  font-style: normal;
  /* font-weight: 700; */
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: 1px;
  padding-left: 0.3rem;
  width: fit-content;
}

.repetir_giroFormDetalleText_gris {
  color: #787878;
}

.repetir_giroFormDetalleMonto_lila {
  color: #614ad3;
}

.repetir_giroFormDetalleMontoRow {
  position: relative;
}

.repetir_giroFormDetalleMontoRowBtnCuponDesc {
  height: 1.5625rem;
  background: #614ad3;
  border-radius: 5px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 0.9rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: -4px;
  left: 0;
  width: 7.5rem;
  cursor: pointer;
}

.repetir_giroFormDetalleTextContainer {
  position: relative;
  margin-right: 0;
  margin-left: auto;
  right: 0;
  left: auto;
  padding-top: 0.2rem;
}

.repetir_giroFormDetalleText {
  letter-spacing: 1px;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  font-size: 0.775rem;
  line-height: 0.9rem;
  width: 10.5rem;
  text-align: right;

  width: 100%;
  display: flex;
}

.repetir_giroFormHasta {
  padding-top: 0.5rem;
}

.repetir_giroText {
  display: flex;
  justify-content: center;
}

.repetir_giroTextSpan1 {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: #614ad3;
  padding-left: 1rem;
}

.repetir_giroTextSpan2 {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: #614ad3;
}

.repetir_giroBtn_container {
  padding-top: 2.5rem;
}

.repetir_giroBtn {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  margin-top: 1rem;
}

.repetir_giroEtapas {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
  padding-bottom: 0.3rem;
}

.repetir_giroFormDetalleTextRowDescuento {
  /* display: flex;
  justify-content: space-between;
  position: relative; */
  padding-right: 1.5rem;
  margin-top: -3px;
}

.repetir_giroFormDetalleTextRowDescuentoBtn {
  margin-left: 0.3rem;
  padding-left: 0.1rem;

  border-radius: 3px;
  border: 1px solid var(--customF-C4C4C4);
  color: #ffffff;
  width: 1.15rem;
  height: 1.05rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}

.repetir_giroFormDetalleTextRowDescuentoBtn img {
  width: 0.7rem;
  height: 0.7rem;
}

.repetir_giro_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.repetir_giro_head img {
  width: 2rem;
}

.repetir_giro_head_t {
  width: 65%;
}

.repetir_giro_head_i {
  width: 35%;
}

.repetir_giro_head_cambiar_texto {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}

.repetir_giroFormOrden {
  display: flex;
  flex-direction: column;
}

.repetir_giro_bot_line {
  border-bottom: 1px solid #ececec;
}

.repetir_giro_Btn_container {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  width: 100%;
  margin-top: 1rem;
}

.repetir_giro_Etapas {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
  padding-bottom: 0.3rem;
}
.repetir_giro_destinatario_flag{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.normal-flag{
  margin: -0.5rem 0rem 0.6rem -0.7rem!important;
}
</style>